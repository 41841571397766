.slides {
  background: #f8f9fc;
  background-image: url('../public/img/img-ornament.svg');
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: bottom left;
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.slides-left {
  width: 50vw;
}

.slides-right {
  width: 50vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.slides p {
  line-height: 2rem;
}
/* STICKY TO TOP HEADER FULL WIDTH */
.slides-header {
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  padding-left: 3.75rem;
  top: 0;
  height: 75px;
  background-color: #e2e5e8;
}

.slides-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slides-card {
  max-width: 500px;
  background: rgba(24, 39, 55, 0.15);
  border-radius: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 2rem;
}

.slides-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phish-icon {
  width: 2.5rem;
}
